import dayjs from "dayjs";
import Conditions from "@/pages/list/Logic/Condition/Conditions";
import { DictionaryQuery } from "@/pages/list/Logic/Condition/QueryType";
import {
  ConstructionTypeInputEnum,
  ConstructionTypeNullableFilterInput,
  FloatNullableFilterInput,
  FloorPlanTypeInputEnum,
  FloorPlanTypeNullableFilterInput,
  IntFilterInput,
  IntNullableFilterInput,
  SearchBuildingsWhereInput,
  SearchRoomsWhereInput,
  StringNullableFilterInput,
} from "~/generated/v2/graphql";
import { categoryConditions } from "~/types/CategoryConditionType";
import RegionConditionType from "~/types/regionCondition/RegionConditionType";
import RouteConditionType from "~/types/routeCondition/RouteConditionType";
import { valueOf } from "~/utils/typeUtil";

export class WheresConverter {
  static search(conditions: Conditions): SearchBuildingsWhereInput | undefined {
    const buildingWhere = buildingRelatedWhere(conditions) ?? {};
    const roomWhere = roomRelatedWhere(conditions);

    if (!buildingWhere && !roomWhere) return;

    return {
      ...buildingWhere,
      ...(roomWhere
        ? {
            search_rooms: {
              some: {
                ...roomWhere,
              },
            },
          }
        : {}),
    };
  }

  static newArrivalBuildings(conditions: Conditions):
    | {
        prefecture_city: {
          prefecture_name: string;
          city_name: string;
        };
      }
    | {
        station: {
          id: number;
          walking_minutes_lte?: number;
        };
      }
    | undefined {
    if (conditions.selectedShikuguns.length + conditions.selectedStations.length !== 1) return;
    if (conditions.selectedShikuguns.length > 0) {
      const selectedArea = conditions.selectedShikuguns[0];
      return {
        prefecture_city: {
          prefecture_name: selectedArea.data.todofuken.display_name,
          city_name: selectedArea.data.display_name,
        },
      };
    }

    const selectedStation = conditions.selectedStations[0];
    const upperFoot = conditions.upperFootCondition;
    return {
      station: {
        id: selectedStation.data.database_id,
        ...(upperFoot ? { walking_minutes_lte: Number(upperFoot) } : {}),
      },
    };
  }

  static newArrivalRoomCount(conditions: Conditions): SearchBuildingsWhereInput {
    const { search_rooms: searchRoomWhere, ...wheres } = this.search(conditions);

    return {
      ...wheres,
      search_rooms: {
        some: {
          ...searchRoomWhere.some,
          room_created_at: {
            gte: dayjs().startOf("day").subtract(7, "d").format("YYYY-MM-DD HH:mm:ss"),
          },
        },
      },
    };
  }
}

function buildingRelatedWhere(conditions: Conditions): SearchBuildingsWhereInput {
  const stationCondition = stationWhere(conditions.routeCondition, conditions.upperFootCondition);

  return {
    AND: [
      ...buildingAgeWhere(
        conditions.lowerBuildingAgeCondition,
        conditions.upperBuildingAgeCondition,
      ),
      ...categoryBuildingWhere(conditions.categoriesCondition),
    ],
    ...areaWhere(conditions.regionCondition),
    ...constructionWhere(
      conditions.constructionCondition as {
        [K in CONSTRUCTION_CONDITION]: boolean;
      },
    ),
    ...stationCondition,
  };
}

function buildingAgeWhere(
  lowerAge: string | undefined,
  upperAge: string | undefined,
): { building_age?: IntNullableFilterInput }[] {
  if (!lowerAge && !upperAge) return [];

  const lowerCondition = lowerAge ? { building_age: { gte: Number(lowerAge) } } : {};
  const upperCondition = upperAge ? { building_age: { lte: Number(upperAge) } } : {};

  return [lowerCondition, upperCondition];
}

type CONSTRUCTION_CONDITION = "1" | "2" | "3" | "4";
const constructionMap: { [K in CONSTRUCTION_CONDITION]: ConstructionTypeInputEnum[] } = {
  // 鉄筋系
  "1": [
    ConstructionTypeInputEnum.ReinforcedConcrete,
    ConstructionTypeInputEnum.ReinforcedSteelConcrete,
  ],
  // 鉄骨系
  "2": [
    ConstructionTypeInputEnum.SteelFrame,
    ConstructionTypeInputEnum.SteelFramePrecast,
    ConstructionTypeInputEnum.LightweightSteelFrame,
  ],
  // 木造
  "3": [ConstructionTypeInputEnum.Wood],
  // その他
  "4": [
    ConstructionTypeInputEnum.Block,
    ConstructionTypeInputEnum.PrecastConcrete,
    ConstructionTypeInputEnum.Other,
    ConstructionTypeInputEnum.AeratedConcrete,
  ],
};
function constructionWhere(constructions: {
  [K in CONSTRUCTION_CONDITION]: boolean;
}): { construction_id?: ConstructionTypeNullableFilterInput } {
  const availableTypes = Object.entries(constructions)
    .map(([construction_group, isAvailable]) => {
      return isAvailable ? constructionMap[construction_group as CONSTRUCTION_CONDITION] : [];
    })
    .flat();

  if (availableTypes.length < 1) return {};

  return { construction_id: { in: availableTypes } };
}

// [FIXME] コードでの検索に修正する
function areaWhere(regionCondition: RegionConditionType): {
  OR?: { prefecture_name?: StringNullableFilterInput; city_name?: StringNullableFilterInput }[];
} {
  if (!regionCondition || !regionCondition.hasCheckedShikugun()) return {};

  return {
    OR: regionCondition.getCheckedShikuguns().map((shikugunCondition) => {
      return {
        prefecture_name: { equals: shikugunCondition.data.todofuken.display_name },
        city_name: { equals: shikugunCondition.data.display_name },
      };
    }),
  };
}

function stationWhere(
  routeCondition: RouteConditionType,
  upperFoot: string | undefined,
): {
  search_stations?: {
    some: {
      station_id?: IntFilterInput;
      walking_minutes?: IntFilterInput;
    };
  };
} {
  if (!routeCondition.hasCheckedStation() && !upperFoot) return {};

  let upperFootCondition = {};
  if (upperFoot) {
    upperFootCondition = {
      walking_minutes: { lte: Number(upperFoot) },
    };
  }
  let stationCondition = {};
  if (routeCondition.hasCheckedStation()) {
    const stationIds = routeCondition
      .getCheckedStations()
      .map((railwayStation) => railwayStation.data.database_id)
      .map(Number);

    stationCondition = {
      station_id: { in: stationIds },
    };
  }

  return {
    search_stations: {
      some: {
        ...stationCondition,
        ...upperFootCondition,
      },
    },
  };
}

function categoryBuildingWhere(
  selectedCategories?: valueOf<DictionaryQuery>,
): SearchBuildingsWhereInput[] {
  if (!selectedCategories) return [];

  const categoryWhereConditions = categoryConditions
    .map((category) => {
      if (!selectedCategories[category.id]) return;
      if (category.entity !== "building") return;

      return category.v2_condition;
    })
    .filter(Boolean);

  return categoryWhereConditions as SearchBuildingsWhereInput[];
}

function roomRelatedWhere(conditions: Conditions): SearchRoomsWhereInput {
  return {
    AND: [
      ...squareWhere(conditions.lowerAreaCondition, conditions.upperAreaCondition),
      ...rentWhere(
        conditions.lowerRentCondition,
        conditions.upperRentCondition,
        conditions.includeAdministrationFeeCondition,
      ),
      ...categoryRoomWhere(conditions.categoriesCondition),
    ],
    ...floorPlanWhere(
      Object.keys(conditions.floorPlanCondition).map((floorPlanText) => {
        return {
          roomNum: Number(floorPlanText.split("_")[0]),
          planName: floorPlanText.split("_")[1] as FLOOR_PLAN_CONDITION["planName"],
        };
      }),
    ),
    ...initialCostScoreWhere(conditions.lowerInitialCostScoreCondition),
    ...propertyAgentNameWhere(conditions.propertyAgentNameCondition),
    ...wordingWhere(conditions.keywordCondition),
  };
}

function squareWhere(
  lowerSquareMeters?: string,
  upperSquareMeters?: string,
): { square_meters?: IntNullableFilterInput }[] {
  if (!lowerSquareMeters && !upperSquareMeters) return [];

  const lowerCondition = lowerSquareMeters
    ? { square_meters: { gte: Number(lowerSquareMeters) } }
    : {};
  const upperCondition = upperSquareMeters
    ? { square_meters: { lte: Number(upperSquareMeters) } }
    : {};

  if (lowerCondition && !upperCondition) return [lowerCondition];
  if (!lowerCondition && upperCondition) return [upperCondition];

  return [lowerCondition, upperCondition];
}

type FLOOR_PLAN_CONDITION = {
  roomNum: number;
  planName: "r" | "k" | "dk" | "ldk" | "more";
};
const planNameMaps: { [K in FLOOR_PLAN_CONDITION["planName"]]: FloorPlanTypeInputEnum[] } = {
  r: [FloorPlanTypeInputEnum.R],
  k: [FloorPlanTypeInputEnum.K, FloorPlanTypeInputEnum.Sk],
  dk: [
    FloorPlanTypeInputEnum.Dk,
    FloorPlanTypeInputEnum.Sdk,
    FloorPlanTypeInputEnum.Lk,
    FloorPlanTypeInputEnum.Slk,
  ],
  ldk: [FloorPlanTypeInputEnum.Ldk, FloorPlanTypeInputEnum.Sldk],
  more: [],
};
function floorPlanWhere(floorPlans: FLOOR_PLAN_CONDITION[]): {
  OR?: { room_count: IntNullableFilterInput; floor_plan_name?: FloorPlanTypeNullableFilterInput }[];
} {
  if (floorPlans.length === 0) return {};

  return {
    OR: floorPlans.map((floorPlan) => {
      if (floorPlan.roomNum > 3) return { room_count: { gte: 4 } };
      if (floorPlan.planName === "r") {
        return {
          room_count: { equals: 1 },
          floor_plan_name: { equals: FloorPlanTypeInputEnum.R },
        };
      }

      return {
        room_count: { equals: floorPlan.roomNum },
        floor_plan_name: { in: planNameMaps[floorPlan.planName] },
      };
    }),
  };
}

function initialCostScoreWhere(lower?: string): { initial_cost_score?: FloatNullableFilterInput } {
  if (!lower) {
    return {};
  }
  return { initial_cost_score: { gte: Number(lower) } };
}

function rentWhere(
  lower?: string,
  upper?: string,
  includeAdministrationFee = false,
): { rent_administration_fee?: IntNullableFilterInput; rent?: IntNullableFilterInput }[] {
  if (!lower && !upper) return [];

  const keyName = includeAdministrationFee ? "rent_administration_fee" : "rent";

  const lowerCondition = lower ? { [keyName]: { gte: Number(lower) } } : {};
  const upperCondition = upper ? { [keyName]: { lte: Number(upper) } } : {};

  return [lowerCondition, upperCondition];
}

function categoryRoomWhere(selectedCategories?: valueOf<DictionaryQuery>): SearchRoomsWhereInput[] {
  if (!selectedCategories) return [];

  const categoryWhereConditions = categoryConditions
    .map((category) => {
      if (!selectedCategories[category.id]) return;
      if (category.entity !== "room") return;

      return category.v2_condition;
    })
    .filter(Boolean);

  return categoryWhereConditions as SearchRoomsWhereInput[];
}

function propertyAgentNameWhere(name: string | undefined): {
  estate_agent_name?: StringNullableFilterInput;
} {
  if (!name) return {};

  return { estate_agent_name: { equals: name } };
}

function wordingWhere(freeWord: string | undefined) {
  if (!freeWord) return {};

  return { document_text: { contains: freeWord } };
}
